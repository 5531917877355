<template>
  <v-main>
    <v-container fluid class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <div class="word title">
            <span>PAGE</span>
            <br>
            <span>N</span>
            <span>O</span>
            <span>T</span>
            <br>
            <span>F</span>
            <span>O</span>
            <span>U</span>
            <span>N</span>
            <span>D</span>
          </div>
        </v-col>
      </v-row>
      <v-btn to="/">GO HOME /</v-btn>
    </v-container>
  </v-main>
</template>
<script>
export default {
  mounted() {
    const spans = document.querySelectorAll('.word span')

    spans.forEach((span, idx) => {
      span.addEventListener('click', (e) => {
        e.target.classList.add('active')
      })
      span.addEventListener('animationend', (e) => {
        e.target.classList.remove('active')
      })

      // Initial animation
      setTimeout(() => {
        span.classList.add('active')
      }, 750 * (idx + 1))
    })

  }
}
</script>
<style>
  @import url('https://fonts.googleapis.com/css?family=Anton|Roboto');

  .word {
    perspective: 1000px;
  }

  .word span {
    cursor: pointer;
    display: inline-block;
    font-size: 100px;
    user-select: none;
    line-height: .8;
  }

  .word span:nth-child(11).active {
    animation: balance 1.5s ease-out;
    transform-origin: bottom left;
  }

  .word span:nth-child(1).active {
    animation: balance 1.5s ease-out;
    transform-origin: bottom left;
  }

  @keyframes balance {
    0%, 100% {
      transform: rotate(0deg);
    }

    30%, 60% {
      transform: rotate(-45deg);
    }
  }

  .word span:nth-child(2).active {
    animation: shrinkjump 1s ease-in-out;
    transform-origin: bottom center;
  }

  @keyframes shrinkjump {
    10%, 35% {
      transform: scale(2, .2) translate(0, 0);
    }

    45%, 50% {
      transform: scale(1) translate(0, -150px);
    }

    80% {
      transform: scale(1) translate(0, 0);
    }
  }

  .word span:nth-child(1).active {
    animation: falling 2s ease-out;
    transform-origin: bottom center;
  }

  @keyframes falling {
    12% {
      transform: rotateX(240deg);
    }

    24% {
      transform: rotateX(150deg);
    }

    36% {
      transform: rotateX(200deg);
    }

    48% {
      transform: rotateX(175deg);
    }

    60%, 85% {
      transform: rotateX(180deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  .word span:nth-child(4).active {
    animation: rotate 1s ease-out;
  }

  @keyframes rotate {
    20%, 80% {
      transform: rotateY(180deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }

  .word span:nth-child(5).active {
    animation: toplong 1.5s linear;
  }

  .word span:nth-child(6).active {
    animation: falling 2s ease-out;
    transform-origin: bottom center;
  }

  .word span:nth-child(10).active {
    animation: falling 2s ease-out;
    transform-origin: bottom center;
  }

  @keyframes falling {
    12% {
      transform: rotateX(240deg);
    }

    24% {
      transform: rotateX(150deg);
    }

    36% {
      transform: rotateX(200deg);
    }

    48% {
      transform: rotateX(175deg);
    }

    60%, 85% {
      transform: rotateX(180deg);
    }

    100% {
      transform: rotateX(0deg);
    }
  }

  .word span:nth-child(7).active {
    animation: rotate 1s ease-out;
  }

  .word span:nth-child(3).active {
    animation: rotate 1s ease-out;
  }

  .word span:nth-child(9).active {
    animation: rotate 1s ease-out;
  }

  @keyframes rotate {
    20%, 80% {
      transform: rotateY(180deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }

  .word span:nth-child(8).active {
    animation: toplong 1.5s linear;
  }

  @keyframes toplong {
    10%, 40% {
      transform: translateY(-48vh) scaleY(1);
    }

    90% {
      transform: translateY(-48vh) scaleY(4);
    }
  }

</style>
