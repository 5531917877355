<template>
  <v-container>
    <v-col cols12>
      <v-card flat class="mb-3">
        <v-card-text>
          <v-row class="ml-1 mr-1">
            <v-col cols="5">
              <v-autocomplete
                v-model="deviceName"
                :items="$store.state.devices"
                outlined
                dense
                item-text="name"
                label="Device"
                placeholder="rig_"
                hide-details
                :loading="isFetchingDevices"
              >
                <template v-slot:item="{ item }">
                  <span class="subtitle-2">{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <span class="caption">{{ item.description }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field label="Processing Server" :placeholder="manifestServerAddress"
                            v-model="manifestServerAddress"
                            dense hide-details clearable flat outlined></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row justify="center" dense class="mb-3">
        <v-col cols="4">
          <v-card flat>
            <v-card-text>
              <span class="overline">Type</span>
              <br>
              <span class="font-weight-bold title">{{ $store.state.parcel.type || 'N/A' }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card flat>
            <v-card-text class="text-end">
              <span class="overline">Size</span>
              <br>
              <span class="font-weight-bold title">{{ $store.state.parcel.size || 'N/A' }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card flat>
            <v-card-text class="text-end">
              <span class="overline">Weight</span>
              <br>
              <span class="font-weight-bold title red--text"
                    v-if="$store.state.parcel.weight && 'status' in $store.state.parcel.weight && $store.state.parcel.weight.status !== 'OK'">{{ $store.state.parcel.weight.status }}</span>
              <span class="font-weight-bold title" v-else>{{ $store.state.parcel.weight.weight || 'N/A' }}</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card flat>
            <v-card-text class="text-end">
              <span class="overline">Avg. Scan Time</span>
              <br>
              <span class="font-weight-bold title">
                {{ avgScanTime || 'N/A' }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-expansion-panels v-model="expandedPanels" multiple flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-medium">RECEIVER DETAILS</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestTo.full_name || 'N/A' }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestTo.mobile_no || 'N/A' }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ $store.state.parcel.manifestTo.full_address || 'N/A' }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-timelapse</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestTo.scan_time || 'N/A' }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-row dense no-gutters justify="end">
              <v-btn text rounded outlined class="mr-3" @click="viewManifestImage($store.state.parcel.manifestTo.image)"
                     :disabled="!$store.state.parcel.manifestTo.image">Image
              </v-btn>
              <v-btn text rounded outlined class="mr-3" @click="viewRawData('manifestTo')"
                     :disabled="!$store.state.parcel.manifestToRaw">Raw Data
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text rounded outlined color="primary" class="mr-3" @click="setScanType('manifestTo', 'domestic')">
                Scan Receiver
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-medium">SENDER DETAILS</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestFrom.full_name || 'N/A' }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestFrom.mobile_no || 'N/A' }}</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestFrom.full_address || 'N/A' }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon small>
                  <v-icon>mdi-timelapse</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $store.state.parcel.manifestFrom.scan_time || 'N/A' }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-row dense no-gutters justify="end">
              <v-btn text rounded outlined class="mr-3"
                     @click="viewManifestImage($store.state.parcel.manifestFrom.image)"
                     :disabled="!$store.state.parcel.manifestFrom.image">Image
              </v-btn>
              <v-btn text rounded outlined class="mr-3" @click="viewRawData('manifestFrom')"
                     :disabled="!$store.state.parcel.manifestFromRaw">Raw Data
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text rounded outlined color="primary" class="mr-3" @click="setScanType('manifestFrom')">Scan Sender
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>

      <v-btn
        block
        rounded
        x-large
        class="mt-5"
        @click="save"
        :loading="isSaving"
        :disabled="isSaving || !($store.state.parcel.manifestToValid && $store.state.parcel.manifestFromValid)"
      >
        Save Parcel Details
      </v-btn>
    </v-col>

    <v-dialog v-model="scanDialog" eager persistent scrollable>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text :style="`height: 600px`">
          <v-row v-show="!image" justify="center" align="center">
            <video ref="video" id="video" autoplay width="100%"></video>
          </v-row>
          <v-img v-if="image" :src="image" aspect-ratio="1.6"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <v-btn
              @click="scanManifest(toScan)"
              :disabled="isLoading || !(video)"
              block
              rounded
              x-large
              color="primary"
              :loading="isLoading"
            >
              Scan
            </v-btn>
            <v-btn
              @click="closeScanDialog"
              block
              rounded
              text
              x-large
              outlined
              color="secondary"
              :disabled="isScanning"
            >Close
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rawDataDialog">
      <v-card>
        <json-viewer
          :value="rawData"
          :expand-depth="3"
          :copyable="{copyText: 'COPY', copiedText: 'Copied to clipboard'}"
          sort
          :theme="`${$vuetify.theme.dark ? 'json-viewer-dark-theme' : 'json-viewer-light-theme'}`"
        ></json-viewer>
        <v-card-actions>
          <v-btn @click="rawDataDialog = false" block rounded outlined text>close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <viewer ref="viewer" @inited="initViewer" :images="[manifestImage]" :options="viewerOptions">
      <img style="display: none;" :src="manifestImage"/>
    </viewer>

  </v-container>
</template>
<script>
import _debounce from 'lodash.debounce'
import JsonViewer from 'vue-json-viewer'
import Vue from 'vue'

Vue.use(JsonViewer)

export default {
  data: () => ({
    video: null,
    canvas: {},
    manifestImageDialog: false,
    manifestImage: null,
    manifest: null,
    image: null,
    expandedPanels: [0, 1, 2],
    parcel: {
      manifestToImage: null,
      manifestTo: null
    },
    scanDialog: false,
    rawDataDialog: false,
    rawData: null,
    interval: null,
    toScan: null,
    mode: 'domestic',
    isLoading: false,
    parcelResp: null,
    isSaving: false,
    isScanning: false,
    isFetchingDevices: false,
    viewerOptions: {
      "inline": false,
      "button": true,
      "navbar": false,
      "title": true,
      "toolbar": false,
      "tooltip": true,
      "movable": true,
      "zoomable": true,
      "rotatable": true,
      "scalable": true,
      "transition": true,
      "fullscreen": false,
      "keyboard": true,
      "url": "data-source"
    }
  }),
  computed: {
    avgScanTime() {
      if (this.$store.state.parcel.manifestTo.scan_time && this.$store.state.parcel.manifestFrom.scan_time) {
        return ((this.$store.state.parcel.manifestTo.scan_time + this.$store.state.parcel.manifestFrom.scan_time) / 2).toFixed(5)
      } else {
        return (this.$store.state.parcel.manifestTo.scan_time || this.$store.state.parcel.manifestFrom.scan_time).toFixed(5)
      }
    },
    deviceName: {
      set: _debounce(function (val) {
        this.$store.commit('setDeviceName', val)
      }, 500),
      get() {
        return this.$store.state.device.name
      }
    },
    manifestServerAddress: {
      set: _debounce(function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal !== '') {
          this.$store.commit('setManifestServerAddress', newVal)
        } else if (!newVal || newVal.length === 0) {
          this.$store.commit('setManifestServerAddress', 'https://api.sali.internal.skunkworks.ai')
        }
      }, 500),
      get() {
        return this.$store.state.manifestServerAddress
      }
    }
  },
  methods: {
    async save() {
      this.isSaving = true
      try {
        const parcelFormData = new FormData()
        if (this.$store.state.parcel.weight.weight)
          parcelFormData.append('weight', this.$store.state.parcel.weight.weight)
        if (this.$store.state.parcel.size)
          parcelFormData.append('size', this.$store.state.parcel.size)
        if (this.$store.state.parcel.type)
          parcelFormData.append('type', this.$store.state.parcel.type)
        parcelFormData.append('manifest_to_id', this.$store.state.parcel.manifestTo.id)
        parcelFormData.append('manifest_fr_id', this.$store.state.parcel.manifestFrom.id)
        parcelFormData.append('created_by', this.$store.state.device.name)

        this.parcelResp = await this.$server.post(this.manifestServerAddress + '/v1/parcels/', {body: parcelFormData}).json().then(() => {
          this.$store.commit('resetParcel')
        })
      } catch (e) {
        console.error('Error on saving!', e)
        this.$store.commit('setState', ['alert', {
          type: 'error',
          message: 'Error on saving manifest data!'
        }])
      }

      this.isSaving = false
    },
    setScanType(scanType, mode) {
      this.initCamera()
      this.toScan = scanType
      this.mode = mode
      this.image = null
      this.canvas = null
      this.isLoading = false
      this.scanDialog = true
    },
    scanManifest(toScan) {
      console.log('Scanning ' + toScan)
      this.isScanning = true
      if (toScan === 'manifestTo') {
        this.scan()
      } else if (toScan === 'manifestFrom') {
        this.scanManifestFrom()
      }
      this.isScanning = false
    },
    viewManifestImage(image) {
      this.manifestImage = image
      this.$viewer.view()
    },
    viewRawData(rawData) {
      this.rawData = this.$store.state.parcel[`${rawData}Raw`]
      this.rawDataDialog = true
    },
    async scan() {
      this.canvas = this.capture()
      this.isLoading = true

      this.parcel.manifestToImage = this.image = this.canvas.toDataURL('image/jpeg')

      let formData = new FormData()
      await this.canvas.toBlob(async blob => {
        formData.append('image', blob, 'manifest_to.jpg')
        formData.append('mode', this.mode)
        formData.append('created_by', this.$store.state.device.name)
        formData.append('raw', "{}")

        let manifestTo = await this.$server.post(this.manifestServerAddress + '/v1/manifests/', {body: formData}).json().catch(error => {
          console.error('Error on parsing recipient manifest.', error)
          this.$store.commit('setState', ['alert', {
            'type': 'error',
            'message': 'Error on parsing recipient manifest.'
          }])
        })

        if (manifestTo) {
          this.$store.commit('setManifestTo', manifestTo)

          this.$server.get(this.manifestServerAddress + `/v1/manifests/${manifestTo.id}/raw`).json().then(raw => {

            this.$store.commit('setManifestToRaw', raw)
            this.$store.commit('setParcelType', raw.parcel.type)
            this.$store.commit('setParcelSize', raw.parcel.size)

            if ('weight' in raw.parcel && raw.parcel.weight && 'data' in raw.parcel.weight) {
              this.$store.commit('setParcelWeight', manifestTo.parcel.weight.data)
            } else {
              this.$store.commit('setParcelWeight', {
                status: 'NOT_OK'
              })
            }
          })

          this.$store.commit('setManifestValid', ['manifestToValid', true])
        }
        this.isLoading = false
        this.scanDialog = false
        this.image = null
      })
    },
    async scanManifestFrom() {
      this.canvas = this.capture()
      this.isLoading = true

      this.parcel.manifestFromImage = this.image = this.canvas.toDataURL('image/jpeg')

      let formData = new FormData()
      formData.append('created_by', this.$store.state.device.name)
      formData.append('raw', "{}")

      await this.canvas.toBlob(async blob => {
        formData.append('image', blob, 'manifest_from.jpg')

        let manifestFrom = await this.$server.post(this.manifestServerAddress + '/v1/manifests/', {body: formData}).json().catch(error => {
          this.$store.commit('setState', ['alert', {'type': 'error', 'message': 'Error on parsing sender manifest.'}])
          console.error('Error on parsing sender manifest.', error)
        })
        if (manifestFrom) {
          this.$store.commit('setManifestFrom', manifestFrom)
          this.$store.commit('setManifestFromImage', blob)
          this.$store.commit('setManifestValid', ['manifestFromValid', true])

          this.$server.get(this.manifestServerAddress + `/v1/manifests/${manifestFrom.id}/raw`).json().then(raw => {
            this.$store.commit('setManifestFromRaw', raw)
          })

        }
        this.isLoading = false
        this.scanDialog = false
        this.image = null
      })
    },
    capture() {
      let w = this.video.videoWidth
      let h = this.video.videoHeight

      let canvas = document.createElement('canvas')
      canvas.width = w
      canvas.height = h

      let context = canvas.getContext('2d')
      context.drawImage(this.video, 0, 0, w, h)

      this.stopVideoStream()

      return canvas
    },
    async pollWeight() {
      let weight = await this.$weight.get('').json()
      if (weight && weight.status === 'SUCCESS') {
        this.$store.commit('setParcelSize', weight.data.weight)
        clearInterval(this.interval)
        this.interval = setInterval(this.pollWeight, 1000)
      }
    },
    stopVideoStream() {
      if (this.video && this.video.srcObject) {
        console.log('Stopping camera stream...')
        this.video.srcObject.getTracks().forEach(track => (track.stop()))
      }
    },
    closeScanDialog() {
      this.scanDialog = false
      this.stopVideoStream()
    },
    async initCamera() {
      this.video = this.$refs.video
      console.log('Init camera...')
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('Trying with 60fps framerate...')
        navigator.mediaDevices.getUserMedia({
          video: {
            width: {ideal: 1920},
            height: {ideal: 1080},
            frameRate: {ideal: 60}
          }
        }).then(stream => {
          console.log('Using camera with 60fps.')
          this.video.srcObject = stream
          this.loadingCamera = false
        }).catch(async () => {
          console.error('Can\'t find camera with 60fps capabilities.')
          console.log('Trying with 25fps...')
          this.video.srcObject = await navigator.mediaDevices.getUserMedia({
            video: {
              width: {min: 1280, ideal: 1920, max: 1920},
              height: {min: 720, ideal: 1080}, max: 1080,
              frameRate: {min: 15, ideal: 25}
            }
          }).then(() => {
            console.log('Using camera with 25fps.')
          }).catch(error => {
            console.error('Error on loading camera.', error)
          })
        })
      }
    },
    initViewer(viewer) {
      this.$viewer = viewer
    },
  },
  beforeMount() {
    // Fetch devices from server
    this.isFetchingDevices = true
    this.$server.get(this.manifestServerAddress + '/v1/devices/').json().then(response => {
      if ('count' in response && response.count)
        this.$store.commit('setState', ['devices', response.results])
      this.isFetchingDevices = false
    }).catch(() => {
      this.isFetchingDevices = false
    })
  },
  mounted() {
    // Assign video tag ref
    this.video = this.$refs.video
  }
}
</script>
